import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Row, Col, Table, Button } from "reactstrap"
import { useAuth0 } from "@auth0/auth0-react"
import { fetch_get } from "../../utils/fetch"
import { PropagateLoader } from "react-spinners"
import Pagination from "@material-ui/lab/Pagination"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import { MdClose } from "react-icons/md"
import { BsGrid } from "react-icons/bs"

const useStyles = makeStyles({
  list: {
    width: 600,
    margin: '10px'
  },
  timeColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px 15px 0.5rem 15px'
  },
  time: {
    display: 'flex',
    flexDirection: 'column'
  }
});

export default function TemporaryDrawer () {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const classes = useStyles();
  const [state, setState] = useState(false);

  const [tableLoading, setTableLoading] = useState(true)
  const [requests, setRequests] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [contentError, setContentError] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [submitFlag, setSubmitFlag]  = useState(null)
  useEffect(() => {
    if (state && isAuthenticated) {
      setStartDate(null)
      setEndDate(null)
      setCurrentPage(1)
      setPageCount(1)
      setRequests([])
      setTableLoading(true)
      setContentError(false)
      getRequests(1).catch(error => {
        console.log(`Error while getting current support requests: ${error}`)
        setContentError("An error occurred while getting the list of current support requests.")
        setTableLoading(false)
      })
    }
  }, [state, isAuthenticated])// eslint-disable-line react-hooks/exhaustive-deps

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState(open);
  };

  useEffect(() => {
    if (state && isAuthenticated) {
      setCurrentPage(1)
      setTableLoading(true)
      setContentError(false)
      getRequests(1).catch(error => {
        console.log(`Error while getting current support requests: ${error}`)
        setContentError("An error occurred while getting the list of current support requests.")
        setTableLoading(false)
      })
    }
  }, [submitFlag]) // eslint-disable-line react-hooks/exhaustive-deps

  function onPageChange (event, page) {
    setCurrentPage(page)
    setTableLoading(true)
    setContentError(false)
    getRequests(page).catch(error => {
      console.log(`Error while getting current support requests: ${error}`)
      setContentError("An error occurred while getting the list of current support requests.")
      setTableLoading(false)
    })
  }

  const getRequests = page => {
    const start = startDate ? moment(startDate).format("yyyy-MM-DD") : null
    const end = endDate ? moment(endDate).format("yyyy-MM-DD") : null

    let url = `users/${user["https://api.codelinaro.org/username"]}/servicedesk/requests/open?page=${page}`
    if (start) url = url + `&startDate=${start}`
    if (end) url = url + `&endDate=${end}`

    setTableLoading(true)
    return getAccessTokenSilently()
      .then(accessToken => fetch_get(url, accessToken))
      .then(response => {
        setRequests(response["data"] || [])
        const totalRecords = response["pagination"]["totalRecords"] || 0
        const pageSize = response["pagination"]["pageSize"] || 10
        setPageCount(Math.ceil(totalRecords / pageSize))
        setTableLoading(false)
      })
  }

  const handle_submit =() => {
    setSubmitFlag(Date.now())
  }

  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
    >
      <h1
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          style={{ padding: '0rem 0.25rem' }}
          onClick={toggleDrawer(false)}
        >
          <MdClose />
        </Button>
      </h1>
      <h1
        className="display-4 font-weight-bold"
        style={{
          fontSize: "24px",
          marginLeft: '0.5rem',
          marginTop: '3rem',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <span className="ml-2">Current Support Requests</span>
      </h1>
      <div className={clsx(classes.timeColumn)}>
        <div lassName={clsx(classes.time)}>
          <span className="mr-2">Start Date:</span>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate ? endDate : new Date()}
            dateFormat="yyyy-MM-dd"
            isClearable
          />
        </div>
        <div lassName={clsx(classes.time)}>
          <span className="mr-2">End Date:</span>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            dateFormat="yyyy-MM-dd"
            isClearable
          />
        </div>
        <div lassName={clsx(classes.time)}>
          <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => handle_submit()}
            >
              <strong>Go</strong>
          </button>
        </div>
      </div>
      {tableLoading && (
        <Col className="d-flex justify-content-center align-items-center" xs={{ size: 12 }}>
          <PropagateLoader
            className="align-self-center"
            size={20}
            color={"#9c3"}
            loading={true}
          />
        </Col>
      )}
      <div style={{ opacity: tableLoading ? ".5" : "1" }}>
        {!tableLoading && contentError ? (
          <p>{contentError}</p>
        ) : requests.length > 0 ? (
          <div id="quotaWrapper">
            <Row className="ml-0 mr-0">
              <Col className="d-flex justify-content-center align-items-center mb-2">
                <Pagination
                  count={pageCount}
                  page={currentPage}
                  onChange={onPageChange}
                  variant="outlined"
                  shape="rounded"
                />
              </Col>
            </Row>
            <Row className="ml-0 mr-0">
              <Col>
                <div className="table-border">
                  <Table>
                    <thead>
                      <tr>
                        <th>Ticket Name</th>
                        <th style={{ textAlign: 'right' }}>Last Updated</th>
                      </tr>
                    </thead>
                    <tbody>
                      {requests.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
                              <a
                                className="linkJump handlePointer"
                                onClick={() => {
                                  window.open(`${item.link}`, "_blank", "noreferrer")
                                }}
                              >
                                {`${item.summary}`}
                              </a>
                            </td>
                            <td style={{ textAlign: 'right' }}>{`${moment(item.updated).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}`}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
            <Row className="ml-0 mr-0">
              <Col className="d-flex justify-content-center align-items-center mb-2">
                <Pagination
                  count={pageCount}
                  page={currentPage}
                  onChange={onPageChange}
                  variant="outlined"
                  shape="rounded"
                />
              </Col>
            </Row>
          </div>
        ) : (
          !tableLoading && <p>There are no current support requests.</p>
        )}
      </div>
    </div>
  );

  return (
    <span>
      <Button color="primary" onClick={toggleDrawer(true)}>
        <BsGrid className="mr-1" />Open Support Tickets
      </Button>
      <Drawer anchor='right' open={state} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </span>
  );
}