import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { Router } from "@reach/router"
import Layout from "../components/layout/layout"
import Content from "../components/content/content"
import ErrorPage from "./404"
import AvailableProjects from "../components/available-projects/available-projects"
import ColServiceIcon from "../components/service-icon/service-icon"
import Header from "../components/header/header"
import ProtectedRoute from "../components/protected-route"
import { useAuth0 } from "@auth0/auth0-react"
import { Loading } from "../components/loading"
import SideBar from "../components/sidebar"
import { Error } from "../components/error"
import { useUserServices } from "../hooks/use-user"
import { useServiceLink } from "../hooks/use-servicelink"
import { PropagateLoader } from "react-spinners"
import { fetch_get, fetch_patch } from "../utils/fetch"
import moment from "moment"
import { sortBy } from "lodash"
import { change_gravatar } from "../utils/common"

const Home = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const { loading } = useUserServices("gitlab")
  const api = `users/${user["https://api.codelinaro.org/username"]}/projects`
  const usersSingle = `metrics/users/${user["https://api.codelinaro.org/username"]}/single`
  const lastLogin = `users/${user["https://api.codelinaro.org/username"]}/lastLogin`
  const usersPicture = user.picture
  const usersName = user.given_name
  const [services, setServices] = useState([])
  const { loadingServiceLink, serviceLink } = useServiceLink()
  // const [createTime, setCreateTime] = useState("")
  const [loginCounts, setLoginCounts] = useState("")
  const [dateLastAccessed, setDateLastAccessed] = useState("")
  const [showRequestIcon, setShowRequestIcon] = useState(false)
  const isSuperAdmin = user["https://api.codelinaro.org/0/role"] === "super-admin"
  const [isOperate, setIsOperate] = useState(false)
  useEffect(() => {
    if (process.env.SENTRY_ENVIRONMENT !== "production" && isAuthenticated) {
      getAccessTokenSilently().then(token => console.log(token))
      // console.log(user)
    }

    let services = []
    const data = user["https://api.codelinaro.org/services"] || {}

    Object.keys(data).forEach(function (key) {
      if (data[key]["status"] === "active") {
        services.push(key)
      }
    })
    services = sortBy(services, function (service) {
      return service === "servicedesk" ? 0 : 1
    })
    setServices(services)

    getAccessTokenSilently()
      .then(accessToken => fetch_get(usersSingle, accessToken))
      .then(response => {
        // const dateCreated = moment(response.dateCreated).format('YYYY-MM-DD HH:mm:ss')
        // const nowDate = moment().format('YYYY-MM-DD HH:mm:ss')
        // const differDay = moment(nowDate).diff(moment(dateCreated), 'days')
        const dateLastAccessed = moment(response.dateLastAccessed).format("MMM. D, YYYY @ LT")
        // setCreateTime(differDay)
        setLoginCounts(response.loginCounts)
        setDateLastAccessed(dateLastAccessed)
        getAccessTokenSilently()
          .then(accessToken => fetch_patch(lastLogin, {}, accessToken))
          .then(response => {})
      })
      .catch(error => {
        console.log(error)
      })
    getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(
          `users/${user["https://api.codelinaro.org/username"]}/servicedesk/requests/open?page=1`,
          accessToken
        )
      )
      .then(response => {
        setShowRequestIcon(!!response.pagination.totalRecords)
      })
      .catch(error => {
        console.log(error)
      })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, user, isAuthenticated, usersSingle])

  useEffect(() => {
    if (loading) return
    if (usersPicture) {
      change_gravatar("dashboard_garvatar", usersPicture)
    }
  }, [loading, usersPicture])

  if (loading) {
    return <></>
  }

  return (
    <Layout pageTitle="Dashboard">
      <Header contentPage={true} className="contentPage" backgroundImage="/dashborad_header.png">
        <div className="row">
          <div className="col-lg-6">
            <h1 className="display-4 font-weight-bold">
              <div style={{ margin: "auto 0" }}>
                <span style={{ fontSize: "28px" }} className="mr-3">
                  Hello, &nbsp;{usersName}
                </span>
                <img
                  id="dashboard_garvatar"
                  src={usersPicture}
                  style={{ height: 50, width: 50, borderRadius: "50%" }}
                  alt=""
                />
              </div>
            </h1>
          </div>
          <div
            className="col-lg-6"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            {showRequestIcon && (
              <div style={{ marginBottom: 8 }}>
                <SideBar anchor="right" />
              </div>
            )}
            <div
              style={{
                float: "right",
                marginRight: 8,
                color: "#696565",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span className="mr-2">Total Logins: {loginCounts}</span>|
              <span className="ml-2">Last Login: {dateLastAccessed}</span>
            </div>
          </div>
        </div>
      </Header>
      <Content>
        <div>
          <div className="mb-3">
            <Col>
              <Row
                className="mt-3"
                style={{ color: "#696565", background: "#666", borderRadius: 12 }}
              >
                <Col>
                  {loadingServiceLink ? (
                    <Row className="py-2">
                      <Col
                        className="d-flex justify-content-center align-items-center"
                        xs={{ size: 12 }}
                        sm={{ size: 4, offset: 4 }}
                      >
                        <PropagateLoader
                          className="align-self-center"
                          size={20}
                          color={"#9c3"}
                          loading={true}
                        />
                      </Col>
                    </Row>
                  ) : services.length === 0 ? null : (
                    <>
                      <Row className="py-2">
                        {services.map(s => (
                          <div key={s} style={{ margin: "0px 0px 0px 3rem" }}>
                            <ColServiceIcon serviceName={s} key={s} serviceLink={serviceLink} />
                          </div>
                        ))}
                        {(isSuperAdmin || isOperate) && (
                          <div key={"CI"} style={{ margin: "0px 0px 0px 3rem" }}>
                            <ColServiceIcon
                              serviceName={"CI"}
                              key={"CI"}
                              target="_self"
                              serviceLink={{ CI: "/ci/dashboard/" }}
                            />
                          </div>
                        )}
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </div>
          <AvailableProjects api={api} setIsOperate={(bol)=>setIsOperate(bol)}/>
        </div>
      </Content>
    </Layout>
  )
}

const Dashboard = () => {
  const { isLoading, error } = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {error && <Error message={error.message} />}
      <Router>
        <ProtectedRoute path="/dashboard" component={Home} />
        <ErrorPage default />
      </Router>
    </>
  )
}

export default Dashboard
