import React from "react"
import { ServiceDisplayName } from "../../utils/service-mappings"
import { Link } from "gatsby"
import { css } from "glamor"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

export default function ServiceIcon(props) {
  const srcPath = `/${props.serviceName}_white.svg`
  const text = ServiceDisplayName(props.serviceName)
  if (props.link !== "false") {
    var link = props.serviceLink[props.serviceName]
  }
  return props.serviceName !== "irc" ? (
    <div className="my-2">
      <a
        href={link}
        rel="noreferrer"
        target={props.target || "_blank"}
        id={`${props.serviceName}-wrapper`}
        style={{ display: "flex" }}
      >
        <img
          className={`img-fluid d-inline-block`}
          {...css(styles.serviceIconImage)}
          src={srcPath}
          alt={props.serviceName}
          id={props.serviceName}
        />
        <span className="ml-1" {...css(styles.serviceName)}>
          {text}
        </span>
      </a>
    </div>
  ) : (
    <UncontrolledDropdown>
      <DropdownToggle nav>
        <div>
          <a
            rel="noreferrer"
            target={props.target || "_blank"}
            id={`${props.serviceName}-wrapper`}
            style={{ display: "flex" }}
          >
            <img
              className={`img-fluid d-inline-block`}
              {...css(styles.serviceIconImage)}
              src={srcPath}
              alt={props.serviceName}
              id={props.serviceName}
            />
            <span
              className="ml-1"
              {...css(styles.serviceName)}
              class="dropdown-toggle nav-link"
              style={{ padding: "0px 0px 0px 4px" }}
            >
              {text}
            </span>
          </a>
        </div>
      </DropdownToggle>
      <DropdownMenu right {...css(styles.dropdown)}>
        <DropdownItem
          tag={Link}
          to="https://webirc.codelinaro.org/"
          target="_blank"
          style={{ color: "rgba(0, 0, 0, 0.5)" }}
        >
          Web Client
        </DropdownItem>
        <DropdownItem
          tag={Link}
          to="https://irc.codelinaro.org:8443/"
          target="_blank"
          style={{ color: "rgba(0, 0, 0, 0.5)" }}
        >
          Proxy
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
const styles = {
  serviceIconImage: {
    height: "40px",
  },
  serviceName: {
    color: "#fff",
    fontSize: "22px",
    margin: "auto",
    // textDecoration: "none !important",
  },
}
