import React, { useState, useEffect } from "react"
import { Button, Row, Col, Input, InputGroup, InputGroupAddon, Tooltip } from "reactstrap"
import { MdClose } from "react-icons/md"
import { FaSearch } from "react-icons/fa"
import { PropagateLoader } from "react-spinners"
import { fetch_get } from "../../utils/fetch"
import * as Sentry from "@sentry/gatsby"
import { css } from "glamor"
import Pagination from "@material-ui/lab/Pagination"
import { Link, navigate } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { useUserOrgs } from "../../hooks/use-user"
import { useSearch } from "../../hooks/use-search"
import _ from "lodash"
import htmlToDraft from "html-to-draftjs"
import { Select, MenuItem } from "@material-ui/core"

function ProjectButton(props) {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  if (!props.URL) {
    return <></>
  }
  let attr =
    props.Label === "Artifacts"
      ? {
          onClick: () => {
            const link =
              props.visibility === "private"
                ? `/projects/${props.parentOrganization}/private/${props.index}/#artifacts`
                : `/projects/${props.parentOrganization}/${props.index}/#artifacts`
            navigate(link)
          },
        }
      : { href: props.URL }
  return (
    <>
      <div className="mr-2" style={{ display: "inline-table",marginRight:0 }}>
        <Button
          {...attr}
          color="primary"
          className="mr-2 viewPublicProjects1"
        >
          <img
            className={`img-fluid d-inline-block`}
            {...css({ height: 25,marginRight:'5px' })}
            src={
              props.isActive
                ? `/${props.Label.toLocaleLowerCase()}.svg`
                : `/${props.Label.toLocaleLowerCase()}-grey.svg`
            }
            alt={props.Label}
            id={`project-${props.index}-${props.Label}`}
          />
          {props.Label}
        </Button>
      </div>
    </>
  )
}

function PublicProjectBlob(props) {
  let data = props.data
  const contentBlock = htmlToDraft(data.description)
  data.description = contentBlock.contentBlocks[0].text || ""

  const link =
    data.visibility === "private"
      ? `/projects/${data.parentOrganization}/private/${data.projectSlug}/`
      : `/projects/${data.parentOrganization}/${data.projectSlug}/`
  const classMap = {
    active: "activeBg",
    inactive: "inactiveBg",
    archived: "archivedBg",
  }
  return (
    <>
      <div
        className={`p-4 my-3 ${classMap[data.status]} mb-2`}
        style={{ backgroundColor: "#ebebeb" }}
      >
        <Link to={link}>
          <h2 {...css(styles.projectTitle)} className={`font-weight-bold`}>
            {data.status === "active" ? (
              <span>{data.name}</span>
            ) : (
              <span>{`${data.name} (${_.upperFirst(data.status)})`}</span>
            )}
          </h2>
        </Link>
        {/* <h5 {...css(styles.projectTitle)} className={`font-weight-bold`}>
          <Link to={link}>
            {data.status === "active" ? (
              <span>{data.name}</span>
            ) : (
              <span>{`${data.name} (${_.upperFirst(data.status)})`}</span>
            )}
          </Link>
        </h5> */}
        <p style={{ marginBottom: "0.5rem", whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
          {contentBlock.contentBlocks.length <= 1 ? data.description : data.description + "... "}
          {contentBlock.contentBlocks.length <= 1 ? null : <Link to={link}>Read more</Link>}
        </p>
        <p className="mb-0">
          <ProjectButton URL={data.gitlabURL} Label="Git" index={data.projectSlug} />
          <ProjectButton
            URL={data.artifactoryURL}
            Label="Artifacts"
            index={data.projectSlug}
            parentOrganization={data.parentOrganization}
            visibility={data.visibility}
          />
          <ProjectButton URL={data.wikiURL} Label="Wiki" index={data.projectSlug} />
          {data.wikiURL.length && data.visibility === "public" ? (
            <ProjectButton
              URL={process.env.CL_API_ENDPOINT + "/projects/" + data.projectId + "/wiki/rss"}
              Label="RSS"
              index={data.projectSlug}
            />
          ) : null}
        </p>
      </div>
    </>
  )
}

export default function AvailableProjects(props) {
  const { getAccessTokenSilently } = useAuth0()
  const [projectList, setProjectList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [noProjects, setNoProjects] = useState("There are no available projects.")
  const [pageCount, setPageCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageTotal, setPageTotal] = useState("")
  const [teamContain, setTeamContain] = useState(false)
  const {
    currentOrg: {
      index,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()

  const callback = v => {
    setCurrentPage(1)
    let searchString = ""
    const searchValue = v?.target?.value || ""
    if (searchValue !== "") {
      searchString += `&query=${searchValue}`
    }
    searchString += `&teamContain=${teamContain}`
    get_project_list(1, searchString)
  }

  const { search, setSearch, callback: handleSearchValue } = useSearch(callback)

  function onPageChange(event, page) {
    setCurrentPage(page)

    let searchString = ""
    if (search !== "") {
      searchString += `&query=${search}`
    }
    searchString += `&teamContain=${teamContain}`
    get_project_list(page, searchString)
  }

  const restartProjectList = () => {
    setCurrentPage(1)
  }

  const getProjects = (currentPage, searchString) => {
    return getAccessTokenSilently().then(accessToken =>
      fetch_get(
        `${props.api}?page=${currentPage}${searchString}${isSuperAdmin ? "" : `&index=${index}`}`,
        accessToken
      )
    )
  }

  function get_project_list(currentPage, searchString) {
    setIsLoading(true)
    getProjects(currentPage, searchString)
      .then(response => {
        setProjectList(response["data"] || [])
        const totalRecords = response["pagination"]["totalRecords"] || 0
        const pageSize = response["pagination"]["pageSize"] || 10
        setPageCount(Math.ceil(totalRecords / pageSize))
        setPageTotal(totalRecords)
        setIsLoading(false)
      })
      .catch(error => {
        console.log(`Error while getting the list of projects: ${error}`)
        console.log(`${props.api}?page=${currentPage}`)
        Sentry.captureException(error)
        setProjectList([])
        setNoProjects("An error occurred while getting the list of projects.")
        setIsLoading(false)
      })
  }
  useEffect(() => {
    if (isSuperAdmin === undefined || isSuperAdmin === null) return
    setCurrentPage(1)
    setProjectList([])
    setTeamContain(false)
    let searchString = ""
    if (search !== "") {
      searchString += `&query=${search}`
    }
    searchString += `&teamContain=false`
    get_project_list(1, searchString)
    if (isSuperAdmin) return
    getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(`CI/user/enabled?index=${index}`, accessToken)
          .then(response => {
            props.setIsOperate(response.ci_enabled)
          })
          .catch(error => {
            console.log(`Error while getting projects have CI enabled: ${error}`)
          })
      )
      .catch(error => {
        console.log(`Error while getting acdcess token: ${error}`)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuperAdmin])

  const emptySearchValue = () => {
    let searchString = ""
    searchString += `&teamContain=${teamContain}`
    get_project_list(1, searchString)
  }

  const handleTeamContainChange = event => {
    setTeamContain(event.target.value)
    setCurrentPage(1)
    let searchString = ""
    if (search !== "") {
      searchString += `&query=${search}`
    }
    searchString += `&teamContain=${event.target.value}`
    get_project_list(1, searchString)
  }

  return (
    <div {...css(styles.publicProjectContainer)}>
      <div className="mb-2" style={{ display: "flex" }}>
        <span style={{ margin: "auto 0", fontSize: "22px", fontWeight: 700, marginRight: 40 }}>
          My projects
        </span>
        <div style={{ width: "40%" }}>
          <InputGroup>
            <Input
              value={search}
              placeholder="Search for a project..."
              onChange={event => handleSearchValue(event)}
              onKeyPress={event => {
                if (event.key === "Enter") {
                  event.preventDefault()
                  document.getElementById("searchButton").click()
                }
              }}
            />
            {search !== "" && (
              <InputGroupAddon className="clear_search" addonType="append">
                <Button
                  onClick={() => {
                    setSearch("")
                    restartProjectList()
                    emptySearchValue()
                  }}
                >
                  <MdClose />
                </Button>
              </InputGroupAddon>
            )}
            <InputGroupAddon addonType="append">
              <Button id="searchButton">
                <FaSearch />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div style={{ width: "15%", marginLeft: 15 }}>
          <span>Base: &nbsp; &nbsp;</span>
          <Select
            value={teamContain}
            onChange={event => handleTeamContainChange(event)}
            style={{ width: 100 }}
          >
            <MenuItem value={false}>user</MenuItem>
            <MenuItem value={true}>team</MenuItem>
          </Select>
        </div>
      </div>
      {isLoading ? (
        <Row>
          <Col
            className="d-flex justify-content-center align-items-center"
            xs={{ size: 12 }}
            sm={{ size: 4, offset: 4 }}
          >
            <PropagateLoader
              className="align-self-center"
              size={20}
              color={"#9c3"}
              loading={true}
            />
          </Col>
        </Row>
      ) : !isLoading && projectList.length === 0 ? (
        <Row>
          <Col xs={{ size: 12 }}>
            <p {...css(styles.noData)}>{noProjects}</p>
          </Col>
        </Row>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              right: "6px",
              top: "13px",
            }}
          >
            <span {...css(styles.pageTotal)}>{`Total ${pageTotal} items`}</span>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={onPageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
          <Row>
            <Col>
              {projectList.map((p, index) => (
                <div key={index}>
                  <PublicProjectBlob index={index} key={p.projectSlug} data={p} />
                </div>
              ))}
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <span {...css(styles.pageTotal)}>{`Total ${pageTotal} items`}</span>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={onPageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </>
      )}
    </div>
  )
}

const styles = {
  projectTitle: {
    color: "#9c3",
    // fontSize: "16px",
  },
  projectContainer: {
    backgroundColor: "#e6e6e6",
  },
  pageTotal: {
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "center",
    lineHeight: "30px",
    marginRight: "5px",
  },
  publicProjectContainer: {
    position: "relative",
    border: "0.75px solid #ebebeb",
    borderRadius: "12px",
    padding: "1rem",
  },
  noData: {
    marginTop: "45px",
  },
}
